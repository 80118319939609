<template>
  <section v-if="stocks.data" class="page__block stocks">
    <div class="container">
      <div class="page__top stocks__top">
        <h2 class="title">{{ $t("stock") }}</h2>
        <div class="page__top-buttons stocks__buttons">
          <locale-router-link
            to="stocks"
            class="page__top-link header-block__link"
          >
            посмотреть все
          </locale-router-link>
          <div v-if="!$isMobile()" class="page__top-arrows stocks__arrows">
            <button class="stocks__prev" @click="prevSlide">
              <img src="@/assets/img/icons/prev-arrow-white.svg" alt="" />
            </button>
            <button class="stocks__next" @click="nextSlide">
              <img src="@/assets/img/icons/next-arrow-white.svg" alt="" />
            </button>
          </div>
        </div>
      </div>
      <swiper
        v-if="stocks.data && !$isMobile()"
        ref="swiperRef"
        :options="swiperOption"
      >
        <swiper-slide v-for="item in stocks.data" :key="item.id">
          <locale-router-link :to="getComponent(item)" class="stocks__item"
            ><img
              v-lazy="
                $helpers.getAbsolutePath(
                  item.attributes.preview_desktop.data.attributes.url
                )
              "
              alt="stock image"
              width="380"
              height="320"
          /></locale-router-link>
        </swiper-slide>
      </swiper>
      <div v-else class="page-stocks__items">
        <locale-router-link
          v-for="item in stocks.data"
          :key="item.id"
          :to="getComponent(item)"
          class="stocks__item"
          ><img
            v-lazy="getStockImage(item)"
            alt="stock image"
            width="380"
            height="320"
        /></locale-router-link>
      </div>
      <!-- <stocks-list v-if="stocks.data" :items="stocks.data" /> -->
    </div>
  </section>
</template>

<script>
import { getPromos } from "@/api";

export default {
  name: "AppStocks",
  components: {
    // StocksList: () => import("@/components/stocks/StocksList.vue"),
  },
  data() {
    return {
      stocks: {
        page: 1,
        pageSize: 6,
        data: null,
        isLastPage: false,
      },
      swiperOption: {
        lazy: true,
        slideTo: 1,
        slidesPerView: 3,
        spaceBetween: 20,
        initialSlide: 1,
        centeredSlides: false,
        responsive: true,
        breakpoints: {
          800: {
            slidesPerView: 3,
          },
          450: {
            slidesPerView: 2,
          },
          350: {
            slidesPerView: 1,
          },
        },
      },
    };
  },
  computed: {
    swiper() {
      return this.$refs?.swiperRef;
    },
  },
  created() {
    getPromos(
      { type: "current" },
      {
        page: this.stocks.page,
        pageSize: this.stocks.pageSize,
      }
    ).then((stocks) => {
      this.stocks.data = stocks.data;
      console.log(this.stocks.data);
    });
  },
  methods: {
    prevSlide() {
      this.swiper?.swiperInstance.slidePrev();
    },
    nextSlide() {
      this.swiper.swiperInstance.slideNext();
    },
    getComponent(item) {
      if (item.attributes.promo_type === "only_discount") {
        return "stocks/products/" + item.id;
      } else {
        return "stocks/" + item.id;
      }
    },
    getStockImage(item) {
      return this.$helpers.getAbsolutePath(
        item.attributes?.preview_desktop?.data?.attributes?.url
      );
    },
  },
};
</script>

<style lang="scss" scoped></style>
